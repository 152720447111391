<template>
  <b-list-group>
    <b-list-group-item
      v-for="item in service_documents"
      :key="item.text"
      class="d-flex"
    >
      <a :href="FILESURL + item.sfd_file_path" target="_blank" class="pr-4">
        <span class="mr-1">
          <feather-icon icon="FileIcon" size="16" />
        </span>
        <span>{{ item.sfd_name }}</span>
      </a>
    </b-list-group-item>

    <div v-if="service_documents.length === 0" class="text-start">
      <h5>No documents available</h5>
    </div>
  </b-list-group>
</template>

<script>
import { BListGroup, BListGroupItem } from "bootstrap-vue";
import { FILESURL } from "@/config";

export default {
  components: {
    BListGroup,
    BListGroupItem,
  },
  data() {
    return {
      FILESURL,
    };
  },
  props: {
    service_documents: {
      type: Array,
      required: true,
    },
  },
};
</script>
